import React, { useState } from 'react'
import Headline from './Headline'
import { useLangContext } from '../context/lang.context'
import { getImage, GatsbyImage } from 'gatsby-plugin-image'
import { globals, s, colors, alpha } from '../style'

const GalleryBlog = ({ images }) => {
  const { lang } = useLangContext()
  const [selectedImage, setSelectedImage] = useState(null)
  const open = selectedImage !== null

  return (
    <>
      <section>
        <Headline
          title={lang === 'pl' ? 'Galeria' : 'Gallery'}
          back={'CARGONITE'}
        />
        <div css={sOuter}>
          <div css={sInner}>
            {images.map((image, id) => (
              <div
                key={id}
                css={sImageOuter}
                onClick={() => setSelectedImage(id)}
                onKeyPress={() => setSelectedImage(id)}
                role='button'
                tabIndex={0}
              >
                <div css={sImageInner}>
                  <GatsbyImage
                    imgStyle={{ objectPosition: '50% 20%' }}
                    className='gallery-image'
                    image={getImage(image)}
                    alt='CARGONITE TEAM'
                    style={{
                      ...globals.absolute,
                      transition: 'transform 0.15s ease-out',
                    }}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      <div
        css={[
          sView,
          open && { opacity: 1 },
          !open && { pointerEvents: 'none' },
        ]}
        onClick={() => setSelectedImage(null)}
      >
        <div css={sBlur} />
        {open && (
          <GatsbyImage
            image={getImage(images[selectedImage])}
            imgStyle={{
              objectFit: 'contain',
              maxHeight: '100vh',
              maxWidth: '100vw',
            }}
            alt='CARGONITE TEAM'
            css={{
              width: '100vw',
              maxWidth: '100vw',
              maxHeight: '100vh',
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            }}
          />
        )}
      </div>
    </>
  )
}

const sView = {
  position: 'fixed',
  top: 0,
  left: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100vw',
  height: '100vh',
  transition: 'opacity 0.4s ease-in-out',
  opacity: 0,
  cursor: 'pointer',
  zIndex: 105,
}

const sBlur = {
  position: 'absolute',
  left: 0,
  right: 0,
  width: '100%',
  height: '100%',
  backgroundColor: colors.black.concat(alpha[66]),
  // backdropFilter: 'blur(10px)',
}

const sOuter = {
  position: 'relative',
  inset: 'auto',
  width: '100%',
  maxWidth: 1280,
  margin: '50px auto 200px',
  padding: '0 50px',
  [s.md_down]: {
    padding: '0 50px',
    margin: '50px auto 100px',
  },
  [s.sm_down]: {
    marginTop: 100,
  },
  [s.xs]: {
    margin: '50px auto 50px',
    padding: '0 25px',
  },
  [s.lg]: {
    padding: '0 50px',
  },
}

const sInner = {
  display: 'flex',
  flexWrap: 'wrap',
}

const sImageOuter = {
  [s.xs]: {
    flexBasis: '50%',
    maxWidth: '50%',
  },
  // [s.sm]: {
  //   flexBasis: '33.33%',
  //   maxWidth: '33.33%',
  // },
  [s.sm]: {
    flexBasis: '25%',
    maxWidth: '25%',
  },
  overflow: 'hidden',
  '&:hover': {
    [s.hover]: { '.gallery-image': { transform: 'scale(1.25)' } },
  },
  cursor: 'pointer',
}

const sImageInner = {
  height: 0,
  paddingTop: '100%',
  position: 'relative',
  top: 0,
  left: 0,
  flexShrink: 0,
  paddingTop: '100%',
  '&::after': {
    content: `""`,
    display: 'block',
    paddingBottom: '100%',
  },
}

export default GalleryBlog
