import React, { useEffect } from 'react'
import { graphql } from 'gatsby'
import { useLangContext } from '../context/lang.context'
import Seo from '../components/Seo'
import SectionImage from '../components/SectionImage'

import { renderRichText } from 'gatsby-source-contentful/rich-text'
import { colors, globals, s } from '../style'
import { sContent } from '../style/style'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { gSubtitle } from '../style/config/globals'
// import NewsAll from '../page/News/NewsAll/NewsAll'
import { options } from '../components/Contentful'
import NewsAll from '../page/News/NewsAll/NewsAll'
import Layout from '../components/Layout'
import GalleryBlog from '../components/GalleryBlog'

export default function News({ data }) {
  const { lang, setLang } = useLangContext()

  const { news } = data

  /* eslint-disable */
  useEffect(() => {
    const newsLang = news?.lang?.slug
    if (newsLang && lang !== newsLang) setLang(newsLang)
  }, [])
  /* eslint-enable */

  if (!news) return <></>

  const {
    title,
    desc,
    content,
    image,
    imagePosition,
    lang: { slug: newsLang },
    category: { slug: newsCategory },
    gallery,
  } = news

  return (
    <Layout>
      <Seo
        title={title}
        description={desc}
        lang={newsLang}
        image={image.file.url}
      />
      <SectionImage
        title={title}
        desc={desc}
        back={'NEWS'}
        image={getImage(image)}
        imagePosition={imagePosition || '50% 50%'}
        isBlog
      />
        <div css={[gSubtitle, sCategory]}>{newsCategory}</div>
      <div css={[sContent, { img: { paddingBottom: '2rem' } }, sBlogContent]}>
        {renderRichText(content, options)}
      </div>
      {gallery && gallery.length > 0 && <GalleryBlog images={gallery} />}
      <NewsAll />
    </Layout>
  )
}

const sCategory = {
  // justifySelf: 'flex-end',
  // alignSelf: 'flex-end',
  textAlign: 'center',
  paddingBottom: 50,
  [s.md]: { textAlign: 'center' },
  // position: 'absolute',
  // left: '50%',
  // top: 0,
  // transform: 'translate(-50%, -100%)',
}

const sBlogContent ={
  marginTop: '150px',
}

export const query = graphql`
  query ($slug: String!) {
    news: contentfulNews(slug: { eq: $slug }) {
      slug
      title
      desc
      publishDate
      lang {
        slug
      }
      category {
        slug
      }
      content {
        raw
        references {
          ... on ContentfulAsset {
            title
            contentful_id
            __typename
            gatsbyImageData(
              layout: CONSTRAINED
              height: 728
              quality: 100
              placeholder: BLURRED
              formats: [JPG]
            )
            sys {
              revision
              type
            }
          }
          # ... on ContentfulNews {
          #   contentful_id
          #   title
          #   slug
          # }
        }
      }
      imagePosition
      image {
        file {
          url
        }
        gatsbyImageData(
          layout: FULL_WIDTH
          quality: 100
          placeholder: BLURRED
          formats: [JPG]
        )
      }
      gallery {
        gatsbyImageData(
          layout: CONSTRAINED
          quality: 100
          placeholder: BLURRED
          formats: [JPG]
        )
      }
    }
  }
`
